<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://leasing-trade.ru" class="auth__header__link">Вернуться на сайт</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
      <a href="https://leasing-trade.ru" class="auth__header__link auth__header__link--hidden">Вернуться на сайт</a>
    </div>
    <div class="auth__body">
      <form class="auth__form">
        <h1 class="auth__form__title">Активация</h1>
        <div class="auth__form__input">
          <label for="password">Пароль</label>
          <input type="password" v-model="password" id="password" placeholder="Пароль">
        </div>
        <div class="auth__form__input">
          <label for="passconf">Повторите пароль</label>
          <input type="password" v-model="passconf" id="passconf" placeholder="Повторите пароль">
        </div>
        <div class="auth__form__actions">
          <button class="btn" @click="regUser" type="button">Сохранить</button>
        </div>
      </form>
      <div class="auth__body__back">
        <AuthBack/>
      </div>
    </div>
  </div>
</template>

<script>
import ACTIVATE_USER from '../graphql/mutations/ActivateUser.gql'
import GraphQLHelper from '../helpers/GraphQLHelper'
import AuthBack from '../components/svg/AuthBack'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Activation',
  data: () => {
    return {
      passconf: '',
      password: ''
    }
  },
  components: {
    AuthBack
  },
  methods: {
    async regUser ({ target }) {
      if (!target.validity.valid) return
      if (!this.password) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Введите пароль'
        })
        return
      }
      if (this.password !== this.passconf) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Пароли не совпадают'
        })
        return
      }
      await this.$apollo.mutate({
        mutation: ACTIVATE_USER,
        variables: {
          authToken: this.$route.query.auth_token,
          password: this.password
        },
        update: (store, { data: { userAuthPass } }) => {
          this.$store.dispatch({
            type: 'setAuthToken',
            token: userAuthPass
          }).then(async () => {
            await this.$router.push({ name: 'home' })
              .then(() => {
                this.$router.go()
              })
          })
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>
